import Api from '@/axios'

export default {
  commonUploadFiles (payload, config) {
    /*    const options = {
      headers: {
        'content-type': 'multipart/form-data'
      }
    } */
    return Api().post('media/upload', payload, config)
  },
  deleteFiles (id) {
    return Api().delete(`media/upload/delete/${id}`)
  }
}
